import React from "react"
import styled from "styled-components"
import { Link as ScrollLink } from "react-scroll"
import { BiChevronRight } from "react-icons/bi"

const StyledScrollLink = styled(ScrollLink)`
  &:hover {
    cursor: pointer;
  }
  .active {
    background-color: blue;
  }
`

const YoutubeInnerLinks = ({ to, naviTitle }) => (
  <StyledScrollLink
    activeClass="active"
    to={to}
    spy={true}
    smooth={true}
    offset={-80}
    duration={500}
  >
    {naviTitle}
    <BiChevronRight />
  </StyledScrollLink>
)

export default YoutubeInnerLinks
