import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import { Element } from "react-scroll"
import YoutubeImage from "./YoutubeImage"
import { media } from "../styles/media"

/**
 * YoutubeImageContainer is used for lazyloading.
 * But scroll effect does not work when using it.
 * So, I decided to stop using the container following.
 * import YoutubeImageContainer from "./YoutubeImageContainer"
 */

export const query = graphql`
  {
    strapiCmcf {
      youtubeSection {
        sectionTitle
        youtubeArticle {
          id
          youtubeID
          youtubeTitle
          youtubeURL
        }
      }
      youtubeSection2 {
        sectionTitle
        youtubeArticle {
          id
          youtubeID
          youtubeTitle
          youtubeURL
        }
      }
      youtubeSection3 {
        sectionTitle
        youtubeArticle {
          id
          youtubeID
          youtubeTitle
          youtubeURL
        }
      }
    }
  }
`

const YoutubeList = styled.div`
  width: 100%;
  .youtubeListArea {
    padding-bottom: 100px;
    padding-top: 50px;
    h3 {
      padding: 0 0 1.62em;
    }
    .youtubeGroup {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: space-between;
      .yg1 {
        width: 28%;
        margin-bottom: 80px;
        /* iframe {
          width: 100%;
          margin-bottom: 0.62em;
        }
        a {
          font-size: 0.8em;
        } */
      }
    }
  }
  ${() => media.sp(css`
    .youtubeListArea{
      padding:30px 0;
      .youtubeGroup{
        .yg1{
          width:48%;
          margin-bottom:60px;
          a{
            font-size:.9em;
            margin-top:.62em;
          }
        }
      }
    }
  `
)}
`

const YoutubeSection = () => {
  const data = useStaticQuery(query)
  const { strapiCmcf: bird } = data

  return (
    <YoutubeList>
      <Element name="youtubeRecommend">
        <div className="youtubeListArea">
          <h3>{bird.youtubeSection.sectionTitle}</h3>
          <div className="youtubeGroup">
            {bird.youtubeSection.youtubeArticle.map(cat => (
              <div className="yg1" key={cat.id}>
                <YoutubeImage id={cat.youtubeID} />
                <a href={cat.youtubeURL}>{cat.youtubeTitle}</a>
              </div>
            ))}
          </div>
        </div>
      </Element>

      <Element name="introProductYoutube">
        <div className="youtubeListArea">
          <h3>{bird.youtubeSection2.sectionTitle}</h3>
          <div className="youtubeGroup">
            {bird.youtubeSection2.youtubeArticle.map(cat => (
              <div className="yg1" key={cat.id}>
                {/* <YoutubeImageContainer article={cat} /> */}
                <YoutubeImage id={cat.youtubeID} />
                <a href={cat.youtubeURL}>{cat.youtubeTitle}</a>
              </div>
            ))}
          </div>
        </div>
      </Element>

      <Element name="animeSeriesYoutube">
        <div className="youtubeListArea">
          <h3>{bird.youtubeSection3.sectionTitle}</h3>
          <div className="youtubeGroup">
            {bird.youtubeSection3.youtubeArticle.map(cat => (
              <div className="yg1" key={cat.id}>
                <YoutubeImage id={cat.youtubeID} />
                <a href={cat.youtubeURL}>{cat.youtubeTitle}</a>
              </div>
            ))}
          </div>
        </div>
      </Element>
    </YoutubeList>
  )
}

export default YoutubeSection
