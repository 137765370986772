// You can choose image quality from following.
/**
 * Medium Quality(320x180px) -> mqdefault
 * High Quality(480x360px) -> hqdefault
 * Standard Definition(640x480px) -> sddefault
 * Maximum Resolution(1920x1080px) -> maxresdefault
 *
 * reference: https://html-css-javascript.com/youtube-thumb/
 *
 * this comment was written by nobuo fukunaga
 */

import React, { useState } from "react"
import styled from "styled-components"

// Used for calculating padding ratio with width
// Retaining the video aspect ratio at 16:9.
// In doing so we set the padding-top of the first div to 56.25%
const createNumberAndSuffix = word => {
  let num
  let suffix
  if (word.endsWith("px")) {
    num = Number(word.replace("px", ""))
    suffix = "px"
  } else if (word.endsWith("em")) {
    num = Number(word.replace("em", ""))
    suffix = "em"
  } else if (word.endsWith("rem")) {
    num = Number(word.replace("rem", ""))
    suffix = "rem"
  } else if (word.endsWith("%")) {
    num = Number(word.replace("%", ""))
    suffix = "%"
  } else {
    throw new Error("cannot create number and suffix")
  }
  return { num, suffix }
}

// CSS
const StyledYoutube = styled.div`
  background-color: #000;
  margin: ${props => props.margin};
  /* margin-bottom: 30px; */
  position: relative;
  /* padding-top: 56.25%; */
  padding-top: ${props => {
    const obj = createNumberAndSuffix(props.width)
    return `${obj.num * 0.5625}${obj.suffix}`
  }};
  overflow: hidden;
  cursor: pointer;
  /* width: 100%; */
  width: ${props => props.width};
`

const StyledPlayButton = styled.div`
  width: 90px;
  height: 60px;
  background-color: #333;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
  z-index: 1;
  opacity: 0.8;
  border-radius: 6px;
  &:before {
    content: "";
    border-style: solid;
    border-width: 15px 0 15px 26px;
    border-color: transparent transparent transparent #fff;
  }
  ${this}, &:before {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    position: absolute;
  }
  :hover {
    background-color: #ff0000;
  }
`

const StyledImg = styled.img`
  width: 100%;
  top: -16.84%;
  left: 0;
  opacity: 0.7;
  position: absolute;
`

const StyledIFrame = styled.iframe`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`

// Component
const YoutubeImage = ({
  id,
  quality = "hqdefault",
  width = "100%",
  margin = "0 0 0 0",
}) => {
  const [isActive, setIsActive] = useState(false)
  const handleImgClick = () => {
    !isActive && setIsActive(true)
  }
  return (
    <StyledYoutube width={width} margin={margin} isActive={isActive}>
      <StyledPlayButton onClick={handleImgClick} />
      <StyledImg
        src={`https://img.youtube.com/vi/${id}/${quality}.jpg`}
        onClick={handleImgClick}
      />
      {isActive && (
        <StyledIFrame
          src={`https://www.youtube.com/embed/${id}?rel=0&showinfo=0&autoplay=1`}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></StyledIFrame>
      )}
    </StyledYoutube>
  )
}

export default YoutubeImage
