import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import { SiYoutubetv } from "react-icons/si"

import { media } from "../styles/media"

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import YoutubeSection from "../components/YoutubeSection"
import HeaderVisual from "../components/HeaderVisual"
import YoutubeInnerLinks from "../components/YoutubeInnerLinks"
import YoutubeImage from "../components/YoutubeImage"

export const query = graphql`
  {
    strapiCmcf {
      pageTitle
      pageSubTitle
      pageMainVisual {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      youtubeHead {
        youtubeRecommend
        anchorSet {
          id
          nabiUrl
          naviTitle
        }
      }
      youtubeSection {
        sectionTitle
        youtubeArticle {
          id
          youtubeID
          youtubeTitle
          youtubeURL
        }
      }
      youtubeSection2 {
        sectionTitle
      }
    }
  }
`

const StyledContentsWrap = styled.div`
  padding: 50px 5% 100px;
  max-width: 1500px;
  margin: 0 auto;
  h3 {
    font-size: 1.32em;
    line-height: 1.62em;
    color: ${({ theme }) => theme.colors.primary.brown};
  }
`

const StyledYoutubeTop = styled.div`
  width: 100%;
  position: relative;
  padding: 80px 50px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  .bigYoutube {
    width: 45%;
    margin-left: 10px;
    iframe {
      width: 100%;
    }
  }
  .backBeige {
    display: block;
    position: absolute;
    background: #e4d8b9;
    width: 80%;
    height: 100%;
    z-index: -1;
    left: 0;
  }
  ${() =>
    media.sp(css`
  padding:30px 0;
    .bigYoutube{
      width:100%;
      margin-left:0;
      margin-bottom;50px;
      order:1;
    }
    .backBeige{
      width:100%;
    }
  `)}
`

const StyledYoutubeTopList = styled.div`
  ${() =>
    media.sp(css`
      width: 90%;
      margin: 30px auto 0px;
      align-items: center;
      order: 2;
    `)}
  width: 30%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-right: 50px;
  .anchorList {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    padding-bottom: 30px;
    a {
      padding: 0.62em 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      svg {
        transform: scale(1.4);
        margin-left: 0.32em;
        path {
          color: #e50012;
        }
      }
    }
  }
  .channelLink {
    font-size: 0.9em;
    color: #e50012;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    svg {
      transform: scale(1.8);
      margin-right: 1em;
      path {
        color: #e50012;
      }
    }
  }
`

const CmcfYoutube = () => {
  const data = useStaticQuery(query)
  const { strapiCmcf: cont } = data

  return (
    <Layout>
      <SEO>
        <title>フランソアのCM | フランソア</title>
        <meta
          name="description"
          content="フランソアの過去のCMや、現在放映中のCMクリップ集です。なつかしのCMもお楽しみください。"
        />
      </SEO>
      <HeaderVisual
        backImage={cont.pageMainVisual.childImageSharp.fluid}
        mainText={cont.pageSubTitle}
        mainTitle={cont.pageTitle}
      />

      <StyledYoutubeTop>
        <div className="backBeige"></div>
        <StyledYoutubeTopList>
          <div className="anchorList">
            <YoutubeInnerLinks
              to="youtubeRecommend"
              naviTitle={cont.youtubeHead.anchorSet[0].naviTitle}
            />
            <YoutubeInnerLinks
              to="introProductYoutube"
              naviTitle={cont.youtubeHead.anchorSet[1].naviTitle}
            />
            <YoutubeInnerLinks
              to="animeSeriesYoutube"
              naviTitle={cont.youtubeHead.anchorSet[2].naviTitle}
            />
          </div>
          <a
            className="channelLink"
            href="https://www.youtube.com/user/francoisjp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiYoutubetv />
            {cont.youtubeHead.anchorSet[3].naviTitle}
          </a>
        </StyledYoutubeTopList>
        {/* <div
          class="bigYoutube"
          dangerouslySetInnerHTML={{
            __html: cont.youtubeHead.youtubeRecommend,
          }}
        /> */}
        <YoutubeImage
          id="iJtk-d-gI7U"
          quality="hqdefault"
          width="45%"
          margin="0 0 0 10px"
        />
      </StyledYoutubeTop>
      <StyledContentsWrap>
        <YoutubeSection />
      </StyledContentsWrap>
    </Layout>
  )
}

export default CmcfYoutube
